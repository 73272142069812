<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'
import equal from 'fast-deep-equal'
import _ from 'lodash'

export default {
  name: 'KuisionerContainer',
  computed: {
    ...mapGetters({
      axios: 'axios',
      isCompletedQuisionary: 'clients/isCompletedQuisionary',
      isHavingPendingQuisionary: 'clients/isHavingPendingQuisionary',
      programFillQuestionnaire: 'clients/programFillQuestionnaire',
    }),
    programId() {
      return this.$route.params.programId
    },
  },
  watch: {
    programId: {
      immediate: true,
      async handler(programId, old) {
        if (equal(programId, old)) return
        // await this.$store
        //   .dispatch("kuisioner/getV2Client", programId)
        // await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
      },
    },
    $route: {
      immediate: true,
      handler(val) {
        if (val.name != 'client.quizionary.done' && this.isCompletedQuisionary) {
          this.$errorToast({
            message: 'Anda sudah mengisi formulir gizi',
          })
          this.$router.push('/')
        }
      }, 
    },
    programFillQuestionnaire: {
      immediate: true,
      handler(val) {
        if (!_.isEmpty(val)) {
          if (!val?.nutritionistId || val?.nutritionistId === null) {
            this.$errorToast({
              message: 'Silahkan pilih ahli gizi dahulu',
            })
            this.$router.push({
              name: 'client.nutritionists',
            })
          }
        }
      },
    },
  },
}
</script>
